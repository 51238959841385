import React, {Component} from "react";
import {Modal, ModalBody, Row, Col} from 'reactstrap';
import {Carousel} from "react-responsive-carousel";

class CustomModal extends Component {
  constructor(props) {
    super(props);
      this.state = {
          currentSlide: 0
      };
  }

  render () {
    return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClick.bind(null, this)}>
          <ModalBody>
            <button className="close" onClick={this.props.onClick.bind(null, this)}/>
            <div className="header-image" style={{backgroundImage: `url(${this.props.content.image})`}}>
                <div className="overlay-container" />
                <div>
                    <p>{this.props.content.category}, {this.props.content.year}</p>
                    <h3>{this.props.content.headline}</h3>
                </div>
            </div>
            <div className="content-container">
                <h4>
                    Das Konzept
                </h4>
                <p>
                    {this.props.content.concept}
                </p>
                <Carousel
                    autoPlay={true}
                    selectedItem={this.state.currentSlide}
                    onChange={this.updateCurrentSlide}
                    infiniteLoop={true}
                    emulateTouch={true}
                    interval={5000}
                    transitionTime={250}
                    showArrows={true}
                    showIndicators={true}
                    showThumbs={false}
                    showStatus={false}
                    swipeable={true}
                    swipeScrollTolerance={1}
                >
                    {this.props.content.altImages.map((value, index) => {
                        return (
                            <div key={index} style={{backgroundImage: `url(${value})`}} />
                        )
                    })}
                </Carousel>
                <h4>
                    Die Umsetzung
                </h4>
                <p>
                    {this.props.content.implementation}
                </p>
                <h4>
                    Informationen
                </h4>
                <Row>
                    <Col lg="3" md="6" sm="6" xs="12">
                        <div>
                            <span>
                                Kategorie
                            </span>
                            <p>
                                {this.props.content.category}
                            </p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12">
                        <div>
                            <span>
                                Projektstatus
                            </span>
                            <p>
                                {this.props.content.status}, {this.props.content.year}
                            </p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12">
                        <div>
                            <span>
                                Standort
                            </span>
                            <p>
                                {this.props.content.place}
                            </p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12">
                        <div>
                            <span>
                                Fläche
                            </span>
                            <p>
                                {this.props.content.area}
                            </p>
                        </div>
                    </Col>
                </Row>
                <h4>
                    Weitere Projekte
                </h4>
                <Row className="projects">

                    {this.props.content.otherContents.map((value, index) => {
                        return (
                            <Col key={value} lg="6" md="6" sm="6" xs="12">
                                <div className="image-container" onClick={this.props.onClick.bind(null, value)} style={{backgroundImage: `url(${this.props.others.find(obj => {
                                        return obj.index === value
                                    }).image})`}}>
                                    <div className="overlay-container" />
                                    <div className="text-container">
                                        <span>{this.props.others.find(obj => {
                                            return obj.index === value
                                        }).category}, {this.props.others.find(obj => {
                                            return obj.index === value
                                        }).year}</span>
                                        <p>{this.props.others.find(obj => {
                                            return obj.index === value
                                        }).headline}</p>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
          </ModalBody>
        </Modal>
    );
  }
}

export default CustomModal;
